import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AuthProvider } from 'providers/authProvider.jsx';
import DefaultRoutes from 'routes/defaultRoutes.jsx';
import Alert from 'components/Alert.jsx';
import { isAuthenticated, parseJwt } from 'infrastructure/auth.js';
import { actionCreators as languageActionCreators } from 'state/language/actions.js';
import I18Provider from 'i18n-translations/provider.jsx';
import { setPreferredLanguageLocale, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import AndroidInterface from 'infrastructure/socket-client/AndroidInterface.js';
import { SocketConnectionStates } from 'constants/enums.js';
import BuildVersion from 'components/BuildVersion.jsx';

const App = () => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const locale = useSelector(state => state.language.locale);
	const [displaySocketConnectionAlert, setDisplaySocketConnectionAlert] = useState(false);
	const [socketConnectionState, setSocketConnectionState] = useState();
	const [startApplication, setStartApplication] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const socketStateTimeout = useRef(null);
	const userSessionRef = useRef(user.userSession);
	const socket = new AndroidInterface();

	useEffect(() => {
		setUserInfo();
		setIsLoading(false);
		setStartApplication(true);
		dispatch(languageActionCreators.setUserLanguage('en-US'));
		setPreferredLanguageLocale('en-US');

		if (window.Android) {
			window.Android.ping = () => {
				window.Android.pong();
			};
		}
	}, []);

	useEffect(() => {
		userSessionRef.current = user.userSession;
	}, [user.userSession]);

	const onConnectionStateChange = async newSocketConnectionState => {
		setDisplaySocketConnectionAlert(true);
		setSocketConnectionState(newSocketConnectionState);

		if (newSocketConnectionState === SocketConnectionStates.AUTHORIZED) {
			if (socketStateTimeout) {
				clearTimeout(socketStateTimeout.current);
			}
			socketStateTimeout.current = setTimeout(() => {
				setDisplaySocketConnectionAlert(false);
			}, 2000);
		}
	};

	if (window.Android) {
		window.Android.connectionStateChanged = state => {
			onConnectionStateChange(state);
		};
	}
	const isUserDeactivated = !startApplication && !window.location.pathname.startsWith('/logout') && isAuthenticated();
	const getDarkModeLoaderColor = () => DarkTheme.colors.grayThree;
	const setUserInfo = () => {
		let token = socket.getToken();
		if (token) {
			const parsedData = parseJwt(token);
			getStorage().setItem('access_token', token);
			getStorage().setItem('userId', parsedData.sub);
		}
	};

	return (
		<>
			{isLoading && (
				<Grid
					columns='1fr'
					rows='1fr'
					stretch='100vh'
					horizAlign='center'
					vertAlign='center'
					backgroundColor={getDarkModeLoaderColor()}>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<>
					{!isUserDeactivated && (
						<AuthProvider>
							<I18Provider locale={locale}>
								<AndroidInterface onConnectionStateChange={onConnectionStateChange}>
									{DefaultRoutes}
									<div className='reconnecting'>
										<Alert
											display={displaySocketConnectionAlert}
											fixed={true}
											persist={true}
											hideCloseButton={true}
											message={socketConnectionState}
											onClose={() => setDisplaySocketConnectionAlert(false)}
										/>
									</div>
									<BuildVersion />
								</AndroidInterface>
							</I18Provider>
						</AuthProvider>
					)}
				</>
			)}
		</>
	);
};

export default App;
