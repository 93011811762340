import { CallWorkflowType } from 'constants/configurationEnums.js';
import { ConditionType, ObservationType } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getDeviceOwnerPatient = async (companyId, deviceId) => {
	try {
		const url = `v1/healthcare/organizations/${companyId}/devices/${deviceId}/patient`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceOwnerPatientWithRetry = async (companyId, deviceId, retryCount = 0) => {
	const maxRetries = 3;
	try {
		const url = `v1/healthcare/organizations/${companyId}/devices/${deviceId}/patient`;
		const response = await gatewayApi.get(url);
		if (response.status === 403) {
			if (retryCount < maxRetries) {
				await new Promise(resolve => setTimeout(resolve, 1000));
				return getDeviceOwnerPatientWithRetry(companyId, deviceId, retryCount + 1);
			} else {
				throw new Error('Max retry attempts reached.');
			}
		} else {
			const { data } = await gatewayApi.get(url);
			return data;
		}
	} catch (error) {
		throw error;
	}
};

export const getDevices = async patientId => {
	try {
		const url = `/v1/companies/${getCompanyId()}/owners/${patientId}/devices`;
		const { data } = await gatewayApi.get(url);
		return data.devices;
	} catch (error) {
		return { error };
	}
};

export const getMyPatients = async ({ pageIndex = 0, pageSize = 20, search = '' } = {}) => {
	try {
		const url = `v1.2/healthcare/patients/mine?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`;
		const { data } = await gatewayApi.get(url);
		return data.patients;
	} catch (error) {
		return { error };
	}
};

export const getPatientPrivacyModeImage = async (objectTypeId, objectId) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/devices/object-types/${objectTypeId}/object-ids/${objectId}/privacy-mode-image`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const searchPatients = async email => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/patients?search=${email}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createPatient = async ({ firstName, lastName, email, channelTypeId, healthSystemId }) => {
	try {
		const { status, data } = await gatewayApi.post(`/v1/healthcare/patients`, {
			companyId: getCompanyId(),
			firstName,
			lastName,
			email,
			channelTypeId,
			healthSystemId,
		});
		return { status, data };
	} catch (error) {
		return { error };
	}
};

export const addPatient = async params => {
	try {
		const { data } = await gatewayApi.post('v1.2/healthcare/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const searchPatient = async (searchText = '', pageIndex = 0, pageSize = 10) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/patients?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchText}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientProfile = async patientId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/patients/${patientId}/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const editPatientProfile = async params => {
	try {
		const { data } = await gatewayApi.put('v1/healthcare/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientLastCondition = async userId => {
	try {
		const { data } = await gatewayApi.get(`v1/fhir/patient/${userId}/last-condition`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientsAiSettings = async ({ patientId, deviceId, roomId, workflowType = CallWorkflowType.MONITORING }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/alerts/companies/${getCompanyId()}/rooms/${roomId}/devices/${deviceId}/patients/${patientId}/ai-settings?workflowType=${workflowType}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientAiSettings = async ({ roomId, deviceId, patientId, sendData }) => {
	try {
		const { data } = await gatewayApi.patch(
			`/v1/alerts/companies/${getCompanyId()}/rooms/${roomId}/devices/${deviceId}/patients/${patientId}/ai-settings`,
			sendData
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientAiSetting = async ({ roomId, deviceId, patientId, fieldToUpdate, value, id = null }) => {
	try {
		const sendData = { [fieldToUpdate]: value };
		const { data } = await gatewayApi.patch(
			`v1/alerts/companies/${getCompanyId()}/rooms/${roomId}/devices/${deviceId}/patients/${patientId}/ai-settings${
				id ? `/${id}` : ''
			}`,
			sendData
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientByIdCard = async idCard => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/patients/${idCard}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const registerPatient = async params => {
	try {
		const { data } = await gatewayApi.post('/v1.1/tasks/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientByUserId = async (companyId, userId) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${companyId}/patient/${userId}/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientDetails = async () => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/patient/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatient = async params => {
	try {
		const { data } = await gatewayApi.put('/v1.1/healthcare/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientById = async patientId => {
	try {
		const { data } = await gatewayApi.get(`/v1.1/healthcare/patients/${patientId}/profile`);
		return data.patient;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemPatients = async ({ healthSystemId, search = '', pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUnassignedPatients = async ({ search = '', pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/patients/unassigned-requests?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNursePatients = async ({ healthSystemId, userId, pageIndex = 0, pageSize = 10, search = '' }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/care-members/${userId}/health-systems/${healthSystemId}/patients?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getOwnerMrn = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/devices/${deviceId}/owner-mrn`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientObservations = async (patientId, observationType) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/patients/${patientId}/observations?observationType=${observationType}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientConditions = async (patientId, conditionType = ConditionType.PRECAUTION) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/patients/${patientId}/conditions?conditionType=${conditionType}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientMobilitySummary = async ({ patientId, startTime = null, endTime = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/alerts/patients/${patientId}/analytics/mobility-summary`, {
			params: { start: startTime, end: endTime },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPressureInjurySummary = async ({ patientId, startTime = null, endTime = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/alerts/patients/${patientId}/analytics/pressure-injury`, {
			params: { start: startTime, end: endTime },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPressureInjuryHistory = async ({ patientId, startTime = null, endTime = null, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(`v1/alerts/patients/${patientId}/analytics/pressure-injury-history`, {
			params: { 'page-size': pageSize, 'page-index': pageIndex, start: startTime, end: endTime },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientAiSettingsHistory = async ({
	roomId,
	deviceId,
	patientId,
	workflowTypeId = CallWorkflowType.MONITORING,
	pageIndex = 0,
	pageSize = 10,
}) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/alerts/companies/${getCompanyId()}/rooms/${roomId}/devices/${deviceId}/patients/${patientId}/ai-settings-edit-history`,
			{
				params: { 'workflow-type': workflowTypeId, 'page-index': pageIndex, 'page-size': pageSize },
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPrimaryPatientCondition = async ownerId => {
	try {
		const { data } = await gatewayApi.get(
			`v1.1/healthcare/patients/${ownerId}/conditions?conditionType=${ConditionType.PRIMARY_PATIENT_CONDITION}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addPrimaryPatientConditions = async ({ conferenceId, ownerId, conditions }) => {
	try {
		const { data } = await gatewayApi.post(`/v1.1/healthcare/patients/${ownerId}/conditions`, { conferenceId, conditions });
		return data;
	} catch (error) {
		return { error };
	}
};

export const removePrimaryPatientConditions = async ({ conferenceId, ownerId, conditions }) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/patients/${ownerId}/conditions/abate`, { conferenceId, conditions });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientInfoObservations = async ownerId => {
	const queryParams = new URLSearchParams({});

	const types = [
		ObservationType.PRIMARY_PATIENT_RISK_FACTOR,
		ObservationType.PRIMARY_ADVERSE_EVENT,
		ObservationType.ADDITIONAL_PATIENT_NOTES,
		ObservationType.ADDITIONAL_TITLE_BAR_NOTES,
	];

	types.forEach(type => queryParams.append('ObservationType', type.toString()));

	try {
		const { data } = await gatewayApi.get(`v1.1/healthcare/patients/${ownerId}/observations?${queryParams.toString()}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addPatientInfoObservations = async ({ healthSystemId, conferenceId, ownerId, observations }) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1.2/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients/${ownerId}/observations`,
			{ conferenceId, observations }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const removePatientInfoObservations = async ({ healthSystemId, conferenceId, ownerId, deviceId, observations }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1.1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients/${ownerId}/observations/abate`,
			{ conferenceId, deviceId, observations }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientCareTeamParticipants = async (healthSystemId, ownerId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${ownerId}/patient/care-teams/monitoring/participants`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setPatientCareTeamParticipants = async ({ healthSystemId, conferenceId, ownerId, participants }) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${ownerId}/patient/care-teams/monitoring`,
			{ conferenceId, participants }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setPatientPreferredLanguage = async ({ conferenceId, ownerId, language }) => {
	try {
		const { data } = await gatewayApi.patch(
			`/v1/healthcare/organizations/${getCompanyId()}/patients/${ownerId}/communication-language`,
			{ conferenceId, language }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setPatientAge = async ({ healthSystemId, conferenceId, ownerId, dateOfBirth }) => {
	try {
		const { data } = await gatewayApi.patch(
			`/v1/healthcare/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients/${ownerId}/date-of-birth`,
			{ conferenceId, dateOfBirth }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setPatientName = async ({ healthSystemId, conferenceId, ownerId, firstName, lastName }) => {
	try {
		const { data } = await gatewayApi.patch(
			`/v1/healthcare/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients/${ownerId}/full-name`,
			{
				conferenceId,
				given: firstName,
				family: lastName,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};
